import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography } from '@pik/pik-ui';

import Button from '../Button';
import { S } from './styles';

const BlockHeader = ({ title, buttonName, buttonUrl}) => (
  <S.Wrapper>
    <Typography type="h4" weight="semiBold">{title}</Typography>
    <Button href={buttonUrl} small>{buttonName}</Button>
  </S.Wrapper>
);

BlockHeader.propTypes = {

};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(BlockHeader);
