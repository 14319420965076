import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography, styleHelpers } from '@pik/pik-ui';

import { S } from './styles';

const AboutUs = ({ data }) => {
  const { title, description } = data;
  return (
    <S.AboutUs>
      <S.Title><Typography type="h1" weight="semiBold" colors={styleHelpers.colors.black}>{title}</Typography></S.Title>
      <S.Description><Typography type="lead" colors={styleHelpers.colors.black}>{description}</Typography></S.Description>
    </S.AboutUs>
  );
};

AboutUs.propTypes = {
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(AboutUs);
