import styled from 'styled-components';
import { media, styleHelpers } from '@pik/pik-ui';

export const S = {};

S.ForInvestors = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 96px;
    
    & * {
        box-sizing: border-box;
    }
`;

S.ForInvestorsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 61px;
    
    @media ${media.max768} {
        flex-direction: column;
        margin-top: 16px;
    }
`;

S.ForInvestorsLink = styled.a`
    display: flex;
    align-items: center;
    margin-top: 23px;
    & *:first-child {
        margin-right: 3px;
    }
    
     @media ${media.max768} {
        margin-top: 16px;
     }
    
`;

S.Title = styled.div`
    & * {
      font-size: 64px;
      line-height: 74px;
      @media ${media.max768} {
        font-size: 32px;
        line-height: 38px;
      }
    }

`;

S.Documents = styled.div`
  flex-grow: 1;
  padding-right: 32px;
  
  @media ${media.max768} {
        padding: 0;
  }

`;

S.DocumentsWrapper = styled.div`


`;

S.DocumentsTitle = styled.div`
    margin-bottom: 16px;
`;

S.DocumentsForInvestors = styled.div`
  flex-grow: 1;
  margin-top: 24px;
  padding-right: 32px;
  
  @media ${media.max768} {
     margin-top: 12px;
     padding: 0;
  }
`;

S.SharePerformance = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 352px;
    height: 256px;
    margin-top: 44px;
    padding: 31px;
    border: 1px solid ${styleHelpers.colors.grayExtraLight};
    box-sizing: border-box;
    border-radius: 8px;
    
    @media ${media.max768} {
        align-self: center;
        margin-top: 16px;
    }

`;

S.SharePerformanceTitle = styled.div`
    margin-bottom: 30px;
`;

S.SharePerformanceCompany = styled.div`
    margin-bottom: 5px;
`;

S.SharePerformanceImage = styled.div`
    & svg {
        max-width: 100%;
    }
`;

S.SharePerformanceLink = styled.div`
    position: absolute;
    right: 24px;
    bottom: 24px;
    
    & svg {
        fill: ${styleHelpers.colors.secondaryMedium}
    }

`;
