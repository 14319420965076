import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const Container = styled.div`
  position: relative;
`;

export const LinkContainer = styled.a`
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  
  &:hover + div {
    background: #F2F3F5;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  padding: 14px 16px 13px 16px;
  display: flex;
  cursor: pointer;
  transition: background .3s ease;
  text-decoration: none;
  margin-bottom: 17px;

  @media ${media.max768} {
    padding: 15px 16px;
  }

  svg {
    margin-top: 2px;
    min-width: 34px;
  }
`;

export const IconWrapper = styled.div`
  min-width: 49px;
  
  @media ${media.max768} {
    min-width: 50px;
  }
`;

export const Title = styled.span`
  display: block;
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  letter-spacing: 0;
  white-space: pre-wrap;
  
  a {
    position: relative;
    z-index: 1;
  }
  
  @media ${media.max768} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Info = styled.span`
  display: block;
  font-size: 12px;
  color: #959BA6;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 16px;
`;
