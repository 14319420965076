import React from 'react';

export default props => (
  <svg width={36} height={46} {...props}>
    <path
      d="M0 0v46h36V13.41L22.184 0H0zm2 2h19v13h13v29H2V2zm21 1.578L32.707 13H23V3.578zM9.27 21c-.782 0-1.614.059-2.27.191V31h2v-4.137h.305c.957 0 1.402.414 1.633 1.715.19 1.164.457 2.14.597 2.422h1.969c-.191-.344-.477-1.402-.723-2.613-.25-1.184-.676-1.848-1.527-2.102v-.023c1.203-.461 1.879-1.364 1.879-2.512 0-.828-.23-1.492-.903-2.027-.69-.547-1.718-.723-2.96-.723zm16 0c-.782 0-1.614.059-2.27.191V31h2v-4.137h.305c.957 0 1.398.414 1.629 1.715.195 1.164.46 2.14.601 2.422h1.969c-.192-.344-.477-1.402-.723-2.613-.25-1.184-.676-1.848-1.527-2.102v-.023c1.207-.461 1.879-1.364 1.879-2.512 0-.828-.23-1.492-.903-2.027-.69-.547-1.718-.723-2.96-.723zm-7.95.047L14.66 31h1.844l.637-2.586h2.375l.64 2.586H22l-2.465-9.953H17.32zm-7.804 1.195c1.226.016 1.484.68 1.484 1.653 0 1.02-.488 1.757-1.625 1.757H9v-3.351a1.7 1.7 0 0 1 .516-.059zm16 0c1.222.016 1.703.68 1.703 1.653 0 1.02-.707 1.757-1.844 1.757H25v-3.351c.156-.043.27-.059.516-.059zM18.328 23h.059c.12.695.937 4 .937 4h-1.969s.852-3.305.973-4z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);
