import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography, styleHelpers, Icons } from '@pik/pik-ui';
import { S } from './styles';
import File from '../File';
import SharePerformance from '../icons/SharePerformance';

const renderDocumentsBlock = (data) => {
  const { title, results } = data;

  return (
    <S.Documents>
      <S.DocumentsTitle><Typography type="h6" weight="semiBold">{title}</Typography></S.DocumentsTitle>
      {results.map((item, index) => <File {...item} key={`document-${index}`} />)}
    </S.Documents>
  );
};

const renderGraphBlock = (data) => {
  const { title, companyName, url } = data;
  return (
    <S.SharePerformance href={url}>
      <S.SharePerformanceTitle><Typography type="h6" weight="medium" color="#939393">{title}</Typography></S.SharePerformanceTitle>
      <S.SharePerformanceImage>
        <SharePerformance />
      </S.SharePerformanceImage>
      <S.SharePerformanceLink>
        <Icons type="arrowRight" />
      </S.SharePerformanceLink>
    </S.SharePerformance>
  );
};

const renderLink = (data) => {
  const { text, url } = data;
  return (
    <S.ForInvestorsLink href={url}>
      <Typography type="h5" weight="medium">{text}</Typography>
      <Icons type="arrowRight" />
    </S.ForInvestorsLink>
  );
};

const renderDocumentsForInvestors = (data) => {
  const { files } = data;
  if(files && files.docs && files.docs.length) {
      return (
          <S.DocumentsForInvestors>
              <S.DocumentsTitle><Typography type="h6" weight="semiBold">{files.text}</Typography></S.DocumentsTitle>
                  <S.DocumentsWrapper>
                          {files.docs.map((item, index) => <File {...item} key={`document-${index}`}/>)}
                      </S.DocumentsWrapper>
          </S.DocumentsForInvestors>
      );
  }
};

const ForInvestors = ({ data, results }) => {
  const {
    title, documents, graph, link,
  } = data;
  return (
    <S.ForInvestors>
      <S.Title><Typography type="h2" weight="semiBold" color={styleHelpers.colors.black}>{title}</Typography></S.Title>
      <S.ForInvestorsWrapper>
        {renderDocumentsBlock({ ...documents, results })}
        {/*{renderGraphBlock(graph)}*/}
      </S.ForInvestorsWrapper>
      {renderLink(link, results)}
      {renderDocumentsForInvestors(data)}
    </S.ForInvestors>
  );
};

ForInvestors.propTypes = {

};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(ForInvestors);
