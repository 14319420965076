import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const S = {};

S.AboutUs = styled.div`
    padding-top: 130px;
    & * {
        box-sizing: border-box;
    }
`;

S.Title = styled.div`
    & * {
        letter-spacing: 0.5px;
        font-size: 64px;
        line-height: 74px;
        
        @media ${media.max768} {
            font-size: 32px;
            line-height: 38px;
        }
    }
    
    
`;

S.Description = styled.div`
    max-width: 667px;
    margin-top: 32px;
    letter-spacing: 0.3px;
    
    @media ${media.max768} {
    margin-top: 16px;
      & * {
        font-size: 20px;
        line-height: 28px;
      }
    }
`;

S.InfoBlocks = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    width: 1216px;
    margin-top: 99px;
    margin-left: -48px;
    
    @media ${media.max1280} {
        width: 100%;
        margin-left: 0;
        
    }
    
    @media ${media.max1120} {
        width: 100%;
        margin-left: 0;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: auto;
    }
    
`;

S.InfoBlock = styled.div`
    box-sizing: border-box;
    padding: 48px;
    padding-bottom: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    @media ${media.max1280} {
        padding: 32px;  
    }
    
    @media ${media.max768} {
        padding-bottom: 99px;  
    }

`;

S.InfoBlockTitle = styled.div`
    letter-spacing: 0.5px;
    @media ${media.max768} {
        & * {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }
    }
`;

S.InfoBlockDescription = styled.div`
    margin-top: 24px;
    letter-spacing: 0.2px;
    & * {
        font-size: 18px;
        line-height: 26px;
    }
    @media ${media.max768} {
       margin-top: 18px;
       & * {
          font-size: 16px;
          line-height: 24px;
        }
    }
`;
