import React from 'react';

export default props => (
  <svg width={35} height={45} {...props}>
    <path
      d="M0 0v45h35V13.119L21.567 0H0zm1.944 1.957h18.473v12.717h12.639v28.37H1.944V1.956zM22.361 3.5l9.438 9.217H22.36V3.5zm1.576 17.498c-2.054 0-3.106 1.322-3.106 2.908 0 .89.512 1.934 1.853 2.755.832.543 1.268.895 1.268 1.617 0 .642-.436 1.116-1.34 1.116-.555 0-1.227-.165-1.618-.382l-.277 1.654c.319.191 1.09.398 1.88.398 1.925 0 3.254-1.189 3.254-2.992 0-1.044-.482-2.041-1.78-2.836-1.064-.642-1.357-.951-1.357-1.54 0-.527.391-1.028 1.212-1.028.55 0 .976.164 1.25.34l.31-1.658c-.292-.176-.907-.352-1.549-.352zm-15.02.088l1.766 4.826-1.838 5.064h1.895l.528-1.789c.144-.558.304-1.158.463-1.792h.03c.175.634.335 1.189.525 1.762l.626 1.819h1.971L12.943 26l1.838-4.914h-1.91l-.494 1.808a86.16 86.16 0 0 1-.41 1.608h-.027c-.163-.554-.292-1.07-.456-1.582l-.565-1.834H8.917zm6.752.004v9.886h4.432v-1.628H17.58V21.09h-1.91z"
      fill="#222"
      fillRule="nonzero"
    />
  </svg>
);
