import React from 'react';

export default () => (
  <svg width="34" height="45" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0v45h34V13.127L20.95 0H0zm1.79 1.8h17.894v12.6h12.527v28.8H1.789V1.8zm19.684 1.273l9.471 9.527h-9.471V3.073zm-11.349 18.97c-.716 0-1.384.077-1.901.2V32.4h1.611v-4.025c.154.017.29.017.458.017.867 0 1.856-.351 2.464-1.072.472-.552.762-1.255.762-2.292 0-.89-.276-1.684-.825-2.205-.608-.58-1.489-.78-2.569-.78zm6.48 0c-.699 0-1.353.077-1.824.2v10.125c.367.06.93.106 1.492.106 1.321 0 2.328-.334 3.01-1.04.775-.764 1.324-2.156 1.324-4.374 0-2.067-.517-3.41-1.356-4.163-.608-.566-1.461-.854-2.646-.854zm5.323.046V32.4h1.629v-4.45h2.418v-1.361h-2.418v-3.076h2.586v-1.424h-4.215zm-5.064 1.269c1.415 0 2.065 1.346 2.065 3.688 0 3.136-1.017 4.085-2.142 4.085-.105 0-.259 0-.395-.032v-7.695c.136-.029.304-.046.472-.046zm-6.466.014c1.094 0 1.489.812 1.489 1.775 0 1.178-.59 1.913-1.653 1.913-.168 0-.277 0-.399-.032V23.45c.136-.045.336-.077.563-.077z"
      fill="#2D2D2C"
      fillRule="nonzero"
    />
  </svg>
);
