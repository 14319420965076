import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const S = {};

S.News = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 128px;

`;

S.NewsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media ${media.max1120} {
    flex-direction: column;
  }
`;

S.NewsTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 32px;
`;

S.NewsTitle = styled.div`
    width: 100%;
    margin-bottom: 32px;
    @media ${media.max768} {
      & * {
        font-size: 32px;
        line-height: 38px;
      }
    }
`;

S.NewsLink = styled.a`
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
  
    @media ${media.max1120} {
    position: static;
    
    & > button {
        width: 100%;
        margin-top: 32px;
    }
    
    }
`;
