import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const S = {};

S.Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  
  @media ${media.max768} {
      margin-bottom: 16px;
      & > *:first-child {
        font-size: 32px;
        line-height: 38px;
      }
  }
`;
