import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { S } from './styles';

import NewsPreview from '../NewsPreview';
import BlockHeader from '../BlockHeader';

const News = ({ data, newsList }) => {
  const { title, link, buttonName } = data;
  return (
    <S.News>
      <BlockHeader
        title={title}
        buttonName={buttonName}
        buttonUrl={link}
      />
      <S.NewsWrapper>
        {newsList.map((item) => <NewsPreview {...item} key={`new-${item.title}`} />)}
      </S.NewsWrapper>
    </S.News>
  );
};
News.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    buttonName: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  newsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(News);
