import React from 'react';

export default props => (
  <svg width={36} height={46} {...props}>
    <path
      d="M0 0v46h36V13.41L22.184 0H0zm2 2h19v13h13v29H2V2zm21 1.578L32.707 13H23V3.578zm-12.566 17.98v1.813h1.785v8.297h1.965v-8.297h1.8V21.56h-5.55zm6.582 0v10.11h1.968v-10.11h-1.968zm3.722 0v10.11h1.965v-4.172h2.281V25.86h-2.28v-2.593h2.429v-1.707h-4.395z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);
