import React from 'react';
import { serverHelpers } from '@pik/pik-utils';
import PropTypes from 'prop-types';

import { RespContainer } from '@pik/pik-ui';

import AboutUs from '../components/AboutUs';
import ForInvestors from '../components/ForInvestors';
import News from '../components/News';

import api from '../common/api';
import { wrapper } from '../services/store';

const Page = ({ pageData }) => {
  const {
    aboutUs, forInvestors, news, results, newsList,
  } = pageData;

  return (
    <>
      <RespContainer>
        <AboutUs data={aboutUs} />
        <ForInvestors data={forInvestors} results={results} />
        <News data={news} newsList={newsList} />
      </RespContainer>
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async (ctx) => {
  try {
    const { appService: { lang } } = ctx.store.getState();
    const pageData = await api.appApi.fetch('GET', `/about/api/v1/page/main?lang=${lang}`);

    return {
      props: {
        pageData,
        lang,
        withoutPageContainer: true,
      },
    };
  } catch (error) {
    const errorObject = serverHelpers.errorFormatter(error, ctx);
    console.error(errorObject);
    ctx.res.statusCode = errorObject.statusCode;

    return {
      props: {
        error: errorObject,
      },
    };
  }
});

Page.propTypes = {
  pageData: PropTypes.shape({
    slider: PropTypes.shape({}).isRequired,
    aboutUs: PropTypes.shape({}).isRequired,
    forInvestors: PropTypes.shape({}).isRequired,
    banner: PropTypes.shape({}).isRequired,
    news: PropTypes.shape({}).isRequired,
    videos: PropTypes.shape({}).isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    newsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default Page;
