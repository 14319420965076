import React from 'react';

export default props => (
  <svg width={36} height={46} {...props}>
    <path
      d="M0 0v46h36V14.168a.904.904 0 0 0 0-.324v-.434L22.184 0H0zm2 2h19v13h13v29H2V2zm21 1.578L32.707 13H23V3.578zm-4.184 18.746c-2.234 0-3.332 2.387-3.332 5.145 0 2.777.903 5.207 3.2 5.207 1.918 0 3.27-1.543 3.27-5.356 0-2.308-.692-4.996-3.138-4.996zm7.965.031c-1.965 0-4.078 1.485-4.078 5.297 0 2.864 1.23 4.993 3.813 4.993.687 0 1.242-.106 1.527-.239l-.2-1.652a3.464 3.464 0 0 1-.94.152c-1.2 0-2.118-.945-2.118-3.36 0-2.503 1.008-3.468 2.117-3.468.434 0 .688.094.914.2l.329-1.653c-.192-.133-.672-.27-1.364-.27zm-16.172.047c-.777 0-1.5.075-1.992.192v9.933c.39.043 1.004.102 1.617.102 1.41 0 2.418-.316 3.094-.973.883-.781 1.406-2.234 1.406-4.277 0-1.965-.449-3.297-1.316-4.078-.645-.598-1.531-.899-2.809-.899zm.364 1.57c1.183 0 1.726 1.262 1.726 3.333 0 2.789-.828 3.722-1.773 3.722a1.39 1.39 0 0 1-.344-.03V24.02c.121-.047.254-.047.39-.047zm7.77.063c.882 0 1.152 1.606 1.152 3.375 0 2.055-.313 3.559-1.137 3.559-.793 0-1.23-1.172-1.23-3.469 0-2.172.437-3.465 1.214-3.465z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);
