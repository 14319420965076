import React from 'react';
import PropTypes from 'prop-types';

import Pdf from './icons/Pdf';
import Doc from './icons/Doc';
import Rar from './icons/Rar';
import Tif from './icons/Tif';
import Xls from './icons/Xls';

import {
  Container,
  LinkContainer,
  Wrapper,
  Title,
  Info,
  IconWrapper,
} from './styles';

const patterns = {
  pdf: <Pdf />,
  xlsx: <Xls />,
  xlsm: <Xls />,
  tif: <Tif />,
  docx: <Doc />,
  doc: <Doc />,
  rar: <Rar />,
  zip: <Rar />,
  '7z': <Rar />,
};

export const propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string,
};

const getIcon = (link) => {
  const splitted = link.split('.');
  const type = splitted[splitted.length - 1];
  const icon = patterns[type];

  if (!icon) {
    return <Pdf />;
  }

  return icon;
};

const File = ({
  title, size, date, link,
}) => {
  let info = `${size}`;

  if (date) {
    info = `${date}, ${info}`;
  }

  return (
    <Container key={link}>
      <LinkContainer target="_blank" href={link} />
      <Wrapper>
        <IconWrapper>
          {getIcon(link)}
        </IconWrapper>
        <div>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Info>{info}</Info>
        </div>
      </Wrapper>
    </Container>

  );
};

File.propTypes = {
  ...propTypes,
};

export default File;
